import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import translations from "../../translations";
import GlobalConfig from "../../shared/GlobalConfig";
import agreementActions from "../../store/actions/agreementAction";
import routePaths from "../../shared/paths.config";

const AgreementPage = () => {
  const [showModal, setShowModal] = useState(false);
  const { lang } = useSelector(state => state?.localization);
  const translation = translations[lang];
  const { validateConfig } = useSelector((state) => state?.appValidate, shallowEqual);
  const { region, visitType, querystring, launchType } = useSelector((state) => state?.appConfig, shallowEqual);
  const { type: deviceType, os: deviceOs, launcherInfo } = useSelector(state => state.deviceDetails, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const consent = (lang === 'en') ? "english" : "spanish";

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);
  
  const openModal = () => {
    if (showModal) {
      onDecline();
    }
    else {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onAccept = () => {
    let showLauncher = launcherInfo.enabled;
    if (deviceType === GlobalConfig.MOBILE) {
      showLauncher = showLauncher && (region === GlobalConfig.WA_REGION ? launcherInfo.kpwa[deviceOs]?.enabled : launcherInfo.kpMobile[deviceOs]?.enabled);
    }

    const response = dispatch(
      agreementActions.postConsentAction(
        {
          ...validateConfig,
          region,
          querystring,
          language: translation.LANG,
          showLauncher,
          launchType: GlobalConfig.LAUNCHTYPES[launchType],
        },
        translation.ACCEPT
      )
    );
    response.then((res) => {
      if (showLauncher && res?.severity !== GlobalConfig.ERROR) {
        navigate(routePaths.appLauncher);
      }
      closeModal();

      if (showModal) {
        closeModal();
      }
    });
  };

  const onDecline = () => {
    dispatch(agreementActions.postConsentAction({ 
      ...validateConfig,
      launchType: GlobalConfig.LAUNCHTYPES[launchType],
      region, 
      querystring, 
      language: translation.LANG 
    }, translation.DECLINE));
    closeModal();
  };

  const agreeBtn = (<Button
    modal=""
    disabled={false}
    tabIndex="0"
    refValue=""
    analyticsName=""
    onClick={onAccept}
    class="vve-modal-agree"
    id="vve-modal-agree"
  >
    {visitType === GlobalConfig.OP_VISIT_TYPE ? translation.OP_ACCEPT_BTN : translation.ACCEPT_BUTTON}
  </Button>
  );

  const declineBtn = (
    <Button
      modal=""
      disabled={false}
      tabIndex="0"
      refValue=""
      analyticsName=""
      onClick={openModal}
      class="vve-modal-decline"
      id="vve-modal-decline"
    >
      {translation.DECLINE_BUTTON}
    </Button>
  );

  const modalFooterProps = (
    <div className="vve-button-div">
      {declineBtn}
      {agreeBtn}
    </div>
  )

  const renderAgreementPage = () => {
    if ((deviceType === GlobalConfig.MOBILE) || (deviceType === GlobalConfig.DESKTOP && deviceOs === (GlobalConfig.IOS || GlobalConfig.AOS))) {
      return (
        <>
          {agreeBtn}
          {declineBtn}
        </>
      )
    } else {
      return (
        <>
          {declineBtn}
          {agreeBtn}
        </>
      )
    }
  }

  const modalBodyVisitType = () => {
    let modalBody = translation.DECLINE_MODAL_BODY;
    if (visitType === GlobalConfig.OP_VISIT_TYPE) {
      modalBody = translation.OP_DECLINE_MODAL_BODY;
    }
    return modalBody;
  }

  return (
    <div className="vve-agreement-page">
      {
        validateConfig && validateConfig?.consent && (
          <>
            <h1 className={`${validateConfig.consent[consent].heading ? 'vve-h1-text' : 'vve-hidden-h1'}`}>{validateConfig.consent[consent].heading || translation.H1_HIDDEN_HEADER_TEXT_OP_ADA}</h1>
            {validateConfig.consent[consent].subHeading && (<p className='vve-Agreement-subHeading' dangerouslySetInnerHTML={{ __html: validateConfig.consent[consent].subHeading }} />)}
            {validateConfig?.consent[consent]?.lists &&
              (<ul className={`Agreement-list ${validateConfig?.consent[consent]?.noShowListStyle ? 'vve-agreement-noList' : ''}`}>
                {
                  validateConfig?.consent[consent]?.lists.map((list, index) => {
                    return <li key={index} dangerouslySetInnerHTML={{ __html: list }}></li>
                  })
                }
              </ul>)}
            {validateConfig.consent[consent].footer && (<p dangerouslySetInnerHTML={{ __html: validateConfig.consent[consent].footer }} />)}
          </>
        )
      }
      <div className="vve-agreement-button-div">
        {renderAgreementPage()}
      </div>

      {showModal ? (
        <Modal
          id="vve-Modal"
          footerProps={modalFooterProps}
          closeModal={closeModal}
          className="vveModal"
          openModal={openModal}
          backdrop="static"
        >
          <>
            <div className="vve-modal-header">
              {translation.DECLINE_MODAL_HEADER}
            </div>
            <div className="vve-modal-body">
              {modalBodyVisitType()}
            </div>
          </>
        </Modal>
      ) : null}


    </div>
  );
}

export default AgreementPage;